import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  // typography: {
  //   // fontFamily: ['Montserrat', 'sans-serif'].join(','),
  // },
  palette: {
    type: 'dark',
    info: {
      main: '#00bcd4',
    },
  },
});

export default theme;
