import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getCustomId } from '../../utils/custom-id.util';

interface MediaSkeletonProps {
  count: number;
  animation?: 'pulse' | 'wave' | false;
  children?: React.ReactNode;
  height?: number | string;
  variant?: 'text' | 'rect' | 'circle';
  width?: number | string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

const MediaSkeleton = (skeletonProps: MediaSkeletonProps): ReactElement => {
  const classes = useStyles();
  const { count } = skeletonProps;

  return (
    <>
      {[...Array(count)].map(() => (
        <Skeleton key={getCustomId()} className={classes.skeleton} {...skeletonProps} />
      ))}
    </>
  );
};

export default MediaSkeleton;
