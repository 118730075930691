import React, { ChangeEvent, ReactElement } from 'react';

import {
    Box,
    Chip,
    createStyles,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
    Tooltip
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import RotateLeftOutlined from '@material-ui/icons/RotateLeftOutlined';
import { Autocomplete } from '@material-ui/lab';

export interface FilterOption {
  title: string;
  value: number;
}

interface ChipFilterProps {
  options: FilterOption[];
  selectedOptions: FilterOption[];
  hint?: string;
  handleOptionChange: (_: ChangeEvent<any>, values: string[]) => void;
  handleSelectAll: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hint: {
      display: 'flex',
      alignItems: 'center',
    },
    infoIcon: {
      marginRight: theme.spacing(1),

      '&:hover ~ $infoText': {
        width: '100%',
      },
    },
    infoText: {
      width: 0,
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      transition: 'width .3s ease-in-out',
    },
  }),
);

export const ChipFilter = ({ options, selectedOptions, handleOptionChange, handleSelectAll, hint }: ChipFilterProps): ReactElement => {
  const classes = useStyles();
  return (
    <>
      {options?.length > 0 && (
        <Autocomplete
          multiple
          id='tags-filled'
          filterSelectedOptions
          options={options.map(opt => opt.title)}
          value={selectedOptions.map(opt => opt.title)}
          onChange={handleOptionChange}
          noOptionsText='Nincs több választási lehetőség!'
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip variant='outlined' label={option} key={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <div>
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                  endAdornment: (
                    <>
                      <InputAdornment position='end' className='MuiAutocomplete-endAdornment' style={{ display: 'block' }}>
                        <IconButton disabled={selectedOptions.length === options.length} size='small' onClick={handleSelectAll}>
                          <Tooltip title='Összes visszaállítása' placement='left-start'>
                            <RotateLeftOutlined />
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
                disabled
                variant='outlined'
                type='number'
                placeholder='Évek kiválasztása'
              />
            </div>
          )}
        />
      )}

      {hint && (
        <Box mt={1} className={classes.hint}>
          <InfoOutlined className={classes.infoIcon} />
          <span className={classes.infoText}>{hint}</span>
        </Box>
      )}
    </>
  );
};
