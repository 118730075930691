import React, { ReactElement } from 'react';
import { Container, Snackbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { RouteProp } from '../../classes/RouteProp';
import { AppConstants } from '../../config/constants';
import RouterPages from '../../pages/RouterPages';
import stores from '../../store/index';
import DrawerInside from './DrawerInside';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none',
    },
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      background: theme.palette.background.paper,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

interface DrawerProps {
  routes: RouteProp[];
}

const ResponsiveDrawer = ({ routes }: DrawerProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => document.body : undefined;
  const pageTitle = stores.useAppStore(state => state.pageTitle);
  const isLoggedIn = stores.useAppStore(state => state.isLoggedIn);
  const { closeSnackbar, snackbarText, isSnackbarShown } = stores.useAppStore();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {isLoggedIn ? (
        <AppBar
          position='fixed'
          className={clsx({
            [classes.appBar]: isLoggedIn,
          })}
        >
          <Toolbar>
            <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap>
              {pageTitle}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : null}
      {isLoggedIn ? (
        <nav
          className={clsx({
            [classes.drawer]: isLoggedIn,
          })}
          aria-label='mailbox folders'
        >
          <Hidden smUp implementation='css'>
            <Drawer
              container={container}
              variant='temporary'
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DrawerInside isMobile handleDrawerToggle={handleDrawerToggle} routes={routes} />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation='css'>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant='persistent'
              open
            >
              <DrawerInside handleDrawerToggle={handleDrawerToggle} routes={routes} />
            </Drawer>
          </Hidden>
        </nav>
      ) : null}
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {/* MAIN CONTENT */}
        <Container>
          <RouterPages />
        </Container>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarShown}
          autoHideDuration={AppConstants.snackbarDuration}
          onClose={closeSnackbar}
          message={snackbarText}
        />
      </main>
    </div>
  );
};

export default ResponsiveDrawer;
