import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Container, createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import YouTube from 'react-youtube';
import { Video } from '../../../pages/videos/classes/Video';

type VideoMetaLink = { url: string; text: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    links: {
      color: theme.palette.info.main,
    },
    htmlContent: {
      overflow: 'hidden',
      maxWidth: '100%',
      '& img': {
        maxWidth: '100%',
      },
      '& a': {
        display: 'block',
        color: theme.palette.info.main,
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    videoPlayerContainer: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      aspectRatio: '16/9',
    },
    videoPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
      border: 'none',
    },
  }),
);

const VideoMedia = ({ ytID, download, downloadHQ, comment }: Partial<Video>): ReactElement => {
  const classes = useStyles();
  const [links, setLinks] = useState<ReactElement[]>([]);

  const createLinks = useCallback((): ReactElement[] => {
    const linkList: VideoMetaLink[] = [];

    if (ytID) {
      linkList.push({ url: `https://www.youtube.com/watch?v=${ytID}`, text: 'Megtekintés új ablakban' });
    }

    if (download) {
      linkList.push({ url: download, text: 'Letöltés (HD)' });
    }

    if (downloadHQ) {
      linkList.push({ url: downloadHQ, text: 'Letöltés (HD+)' });
    }

    return linkList.map((link, index) => (
      <Box component='span' key={link.url}>
        <Box component='span'>
          <Link className={classes.links} href={link.url} target='_blank' variant='body2'>
            {link.text}
          </Link>
        </Box>
        {index < linkList.length - 1 ? <Box component='span'> | </Box> : null}
      </Box>
    ));
  }, [ytID, download, downloadHQ, classes.links]);

  useEffect(() => {
    setLinks(createLinks());
  }, [createLinks, ytID, download, downloadHQ]);

  return (
    <Container className={classes.dialog} disableGutters>
      <Box mb={2}>Linkek: {links}</Box>

      <Box className={classes.videoPlayerContainer} pb={3}>
        <YouTube className={classes.videoPlayer} videoId={ytID} />
      </Box>

      {comment ? <div className={classes.htmlContent} dangerouslySetInnerHTML={{ __html: comment }} /> : null}
    </Container>
  );
};

export default VideoMedia;
