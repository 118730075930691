import { MediaItem } from '../../classes/MediaItem';
import { Photo } from '../../pages/photos/classes/Photo';

export function photoToMediaItem({ short, title, url: poster, isPinned }: Photo): MediaItem {
  return {
    createdDate: undefined,
    isPinned,
    poster,
    short,
    title,
  };
}
