import { useCallback, useState } from 'react';
import { Photo } from '../../pages/photos/classes/Photo';
import { Video } from '../../pages/videos/classes/Video';

interface MediaSelectionProps {
  isMediaLoaded: boolean;
  medias: (Photo | Video)[];
}

type UseMediaSelectionType = [(shortName: string) => void, Photo | Video | undefined];

const useMediaSelection = ({ isMediaLoaded, medias }: MediaSelectionProps): UseMediaSelectionType => {
  const [selectedMedia, setSelectedMedia] = useState<Photo | Video>();

  const handleMediaSelection = useCallback(
    (shortName: string) => {
      if (isMediaLoaded) {
        const media = medias.find(({ short }) => short === shortName);
        setSelectedMedia(media);
      }
    },
    [isMediaLoaded, medias],
  );

  return [handleMediaSelection, selectedMedia];
};

export default useMediaSelection;
