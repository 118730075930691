import React, { Component, ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import stores from '../../../store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withGuard = (ChildComp: ComponentType<any | string>) =>
  class AuthGuard extends Component {
    state = {
      isUserLoggedIn: !!stores.useUserStore.getState().user,
    };

    render() {
      return <>{this.state.isUserLoggedIn ? <ChildComp /> : <Redirect to='/login' />}</>;
    }
  };

export default withGuard;
