import React, { ReactElement, useEffect, useState } from 'react';
import formatDate from '../../utils/format-date.util';
import Tag from '../tag/Tag';

interface UploadDateProps {
  date: string | Date;
}

const UploadDate = ({ date }: UploadDateProps): ReactElement => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    setFormattedDate(formatDate(date));
  }, [date]);

  return <Tag text={`Feltöltve: ${formattedDate}`} />;
};

export default UploadDate;
