import React, { VFC } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import useLogout from '../../shared/hooks/useLogout';
import { useQuery } from '../../shared/hooks/useQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    a: {
      textDecoration: 'none',
    },
    info: {
      marginBottom: theme.spacing(2),
    },
    firstLoginContainer: {
      display: 'block',
      borderRadius: 10,
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
    },
  }),
);

const FirstLogin: VFC = () => {
  const classes = useStyles();
  const query = useQuery();

  useLogout();

  return (
    <Box className={classes.firstLoginContainer}>
      <Typography variant='h6' className={classes.info}>
        E-mail cím ({query.get('email')}) még nincs engedélyezve. Írj Facebook-on és engedélyezem az e-mail címed a folytatáshoz.
      </Typography>
      <Box textAlign='center'>
        <Link to={'/login'} className={classes.a}>
          <Button variant='contained'>Vissza a bejelentkezés oldalra</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default FirstLogin;
