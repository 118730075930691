import dayjs from 'dayjs';
import { initializeApp } from 'firebase/app';
import {
    browserSessionPersistence,
    getAuth,
    GoogleAuthProvider,
    setPersistence,
    signInWithPopup,
    UserCredential
} from 'firebase/auth';
import { child, get, getDatabase, ref, set } from 'firebase/database';

import { firebaseConfig } from '../../config/firebase';
import { Photo } from '../../pages/photos/classes/Photo';
import { Video } from '../../pages/videos/classes/Video';
import { User } from '../auth/classes/User';
import { UserLevel } from '../auth/classes/UserLevel';

initializeApp(firebaseConfig);
const usersPath = '/users';
const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.email');
const database = getDatabase();

export class FirebaseService {
  static checkIsLoggedInStatus(): Promise<boolean> {
    const auth = getAuth();

    return new Promise(resolve => {
      auth.onAuthStateChanged(user => resolve(Boolean(user)));
    });
  }

  static getAuthenticatedUserId(): string | null {
    const auth = getAuth();
    return auth.currentUser?.uid || null;
  }

  static async getUserById(userId: string): Promise<User | null> {
    try {
      const userSnapshot = await get(child(ref(database), `${usersPath}/${userId}`));

      if (!userSnapshot.exists()) {
        console.warn('User not found!');
        return null;
      }

      return userSnapshot.val() as User;
    } catch (err) {
      console.error('Error while requesting user info!', err);
      return null;
    }
  }

  static setUserLastLoginDate(userId: string): Promise<void> {
    return set(ref(database, `${usersPath}/${userId}/lastLogin`), dayjs(new Date()).toISOString());
  }

  static async createUser(userId: string, displayName: string, email?: string, photoURL?: string): Promise<User> {
    const createdDate = dayjs(new Date()).toISOString();
    const user: User = {
      isConfirmed: false,
      createdDate,
      displayName,
      id: userId,
      lastLogin: createdDate,
      level: UserLevel.Advanced,
      photo: photoURL || '',
      email: email || '',
    };

    await set(ref(database, `${usersPath}/${userId}`), user);

    return user;
  }

  static async signInWithGooglePopup(): Promise<UserCredential> {
    try {
      await setPersistence(getAuth(), browserSessionPersistence);
      return signInWithPopup(getAuth(), provider);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static logout(): Promise<void> {
    const auth = getAuth();
    return auth.signOut();
  }

  async getVideos(): Promise<Video[]> {
    return this.getMedia<Video>('/videos');
  }

  async getPhotos(): Promise<Photo[]> {
    return this.getMedia<Photo>('/photos');
  }

  private async getMedia<T extends Photo | Video>(path: string): Promise<T[]> {
    try {
      const mediaSnapshot = await get(child(ref(database), path));

      if (!mediaSnapshot.exists()) {
        throw new Error('No media found!');
      }

      return mediaSnapshot.val() as T[];
    } catch (err) {
      throw new Error(`Error while retrieving media! path: ${path} ${err}`);
    }
  }
}
