import React, { ReactElement } from 'react';

import { Route, Switch } from 'react-router-dom';

import FirstLogin from './first-login/FirstLogin';
import Login from './login/Login';
import Photos from './photos/Photos';
import Videos from './videos/Videos';

const RouterPages = (): ReactElement => (
  <Switch>
    <Route path='/photos' component={Photos} />
    <Route path='/videos' component={Videos} />
    <Route path='/first-login' component={FirstLogin} />
    <Route path='/' component={Login} />
  </Switch>
);
export default RouterPages;
