import React, { ReactElement, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Camera';
import ExitToApp from '@material-ui/icons/ExitToApp';
import VideoIcon from '@material-ui/icons/PlayArrow';

import AppLoader from './shared/layout/AppLoader';
import ResponsiveDrawer from './shared/layout/ResponsiveDrawer';
import { FirebaseService } from './shared/services/firebase.service';
import stores from './store';
import theme from './theme/theme';

const routes = [
  {
    text: 'Videók',
    link: '/videos',
    icon: <VideoIcon />,
  },
  {
    text: 'Fotók',
    link: 'photos',
    icon: <CameraIcon />,
  },
  {
    text: 'Kilépés',
    link: '/login?forceLogout=true',
    icon: <ExitToApp />,
  },
];

const App = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const setUser = stores.useUserStore(storeState => storeState.setUser);
  const { openSnackbar } = stores.useAppStore();

  const goToLogin = (): void => {
    setUser(undefined);
    setIsLoading(false);
    history.push('/login');
  };

  const handleAuth = async (): Promise<void> => {
    const isLoggedIn = await FirebaseService.checkIsLoggedInStatus();

    if (!isLoggedIn) {
      goToLogin();
      return;
    }

    const userId = FirebaseService.getAuthenticatedUserId();

    if (!userId) {
      openSnackbar('Nincs aktív bejelentkezés!');
      goToLogin();
      return;
    }

    const user = await FirebaseService.getUserById(userId);

    if (!user?.isConfirmed) {
      openSnackbar('Nem aktivált felhasználó!');
      goToLogin();
      return;
    }

    await FirebaseService.setUserLastLoginDate(userId);
    setUser(user);
    setIsLoading(false);
  };

  useEffect(() => {
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MuiThemeProvider theme={theme}>{isLoading ? <AppLoader /> : <ResponsiveDrawer routes={routes} />}</MuiThemeProvider>;
};

export default App;
