import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TurnedIn from '@material-ui/icons/TurnedIn';
import clsx from 'clsx';
import MediaCardPoster from './MediaCardPoster';
import UploadDate from './UploadDate';

interface MediaCardProps {
  poster: string;
  title: string;
  date?: string;
  isPinned?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      display: 'block',
      position: 'relative',
      borderRadius: 10,
      overflow: 'hidden',
      height: '100%',
      background: theme.palette.background.paper,
    },
    card: {
      display: 'block',
      position: 'relative',
      width: '100%',
      color: theme.palette.text.primary,

      '&.title': {
        fontSize: 18,
        paddingBottom: ({ date }: { date: string }) => theme.spacing(date ? 8 : 1),
      },

      '&.upload-date': {
        position: 'absolute',
        bottom: theme.spacing(1),
        fontSize: 12,
      },
    },
    pinned: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: 'white',
      fontSize: 40,
      filter: 'drop-shadow(0 0 15px #000000)',
    },
  }),
);

const MediaCard = ({ poster, title, date, isPinned }: MediaCardProps): ReactElement => {
  const classes = useStyles({ date: date || '' });

  return (
    <div className={clsx(classes.cardContainer, classes.card)}>
      {isPinned ? (
        <Box component='div' className={classes.pinned}>
          <Tooltip title='Kiemelt, rögzített galéria' placement='top'>
            <TurnedIn fontSize='inherit' />
          </Tooltip>
        </Box>
      ) : null}

      <MediaCardPoster poster={poster} />

      <Box component='span' px={3} py={1} className={clsx(classes.card, 'title')}>
        {title}
      </Box>

      {date ? (
        <Box component='span' px={3} py={1} className={clsx(classes.card, 'upload-date')}>
          <UploadDate date={date} />
        </Box>
      ) : null}
    </div>
  );
};

export default MediaCard;
