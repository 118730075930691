import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import { Route } from 'react-router-dom';

import { Box } from '@material-ui/core';

import withGuard from '../../shared/auth/hoc/withGuard';
import { ChipFilter, FilterOption } from '../../shared/components';
import MediaDialog from '../../shared/components/media-dialog/MediaDialog';
import useMediaSelection from '../../shared/hooks/useMediaSelection';
import MediaGrid from '../../shared/layout/MediaGrid';
import { getYear } from '../../shared/utils/get-year.util';
import stores from '../../store';
import { Video } from './classes/Video';

const Videos = (): ReactElement => {
  const setVideos = stores.useMediaStore(state => state.setVideos);
  const setPageTitle = stores.useAppStore(state => state.setPageTitle);
  const isVideosLoaded = stores.useMediaStore(state => state.isVideosLoaded);
  const storedVideos = stores.useMediaStore(state => state.videos);
  const [shownVideos, setShownVideos] = useState<Video[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<FilterOption[]>([]);

  const [handleMediaSelection, selectedMedia] = useMediaSelection({ isMediaLoaded: isVideosLoaded, medias: storedVideos });

  const getFilterOptions = useCallback((): FilterOption[] => {
    const years: Set<number> = new Set();

    storedVideos.forEach(video => years.add(getYear(video.createdDate)));

    return Array.from(years).map(year => ({ title: year.toString(), value: year }));
  }, [storedVideos]);

  const setVideoGridInitialState = useCallback(() => {
    setVideos();
    setShownVideos(storedVideos);
    setFilterOptions(getFilterOptions());
    setSelectedFilterOptions(getFilterOptions());
  }, [setVideos, setShownVideos, storedVideos, getFilterOptions]);

  useEffect(() => {
    setVideoGridInitialState();
  }, [setVideoGridInitialState]);

  useEffect(() => {
    const filterActiveText = storedVideos.length !== shownVideos.length ? ' - szűrt lista' : '';
    setPageTitle(`Videók (${shownVideos.length} darab${filterActiveText})`);
  }, [setPageTitle, shownVideos, storedVideos.length]);

  const buildVideoListByYears = (years: string[]): Video[] => {
    const filteredVideoList: Video[] = [];

    years
      .sort()
      .reverse()
      .forEach(year => {
        storedVideos.forEach(video => {
          if (video.year?.toString() === year) {
            filteredVideoList.push(video);
          }
        });
      });

    return filteredVideoList;
  };

  const handleYearChange = (_: ChangeEvent<any>, values: string[]) => {
    setSelectedFilterOptions(values.map(v => ({ title: v, value: +v })));
    setShownVideos(buildVideoListByYears(values));
  };

  const handleSelectAll = () => {
    const allOptions = getFilterOptions();

    setSelectedFilterOptions(allOptions);
    setShownVideos(buildVideoListByYears(allOptions.map(opt => opt.title)));
  };

  return (
    <>
      <Box mb={3}>
        <ChipFilter
          selectedOptions={selectedFilterOptions}
          options={filterOptions}
          handleOptionChange={handleYearChange}
          handleSelectAll={handleSelectAll}
          hint='Szűrés feltöltés éve szerint'
        />
      </Box>
      <MediaGrid isListLoading={!isVideosLoaded} items={shownVideos} modalRoute='videos' onMediaSelected={handleMediaSelection} />
      <Route
        path='/videos/:short'
        component={() => (
          <MediaDialog media={selectedMedia} medias={storedVideos} isMediaLoaded={isVideosLoaded} navigateBackRoute='/videos' />
        )}
      />
    </>
  );
};

export default withGuard(Videos);
