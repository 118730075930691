import { FirebaseService } from './firebase.service';
import { VideoService } from './video.service';
import sharedService from './shared.service';
import { PhotoService } from './photo.service';

class ServiceProvider {
  private readonly firebaseServiceInstance!: FirebaseService;

  private videoServiceInstance!: VideoService;

  private photoServiceInstance!: PhotoService;

  constructor() {
    this.firebaseServiceInstance = sharedService.firebaseService;
  }

  get firebaseService() {
    return this.firebaseServiceInstance;
  }

  get videoService(): VideoService {
    if (!this.videoServiceInstance) {
      this.videoServiceInstance = new VideoService();
    }

    return this.videoServiceInstance;
  }

  get photoService(): PhotoService {
    if (!this.photoServiceInstance) {
      this.photoServiceInstance = new PhotoService();
    }

    return this.photoServiceInstance;
  }
}

export default new ServiceProvider();
