import React, { ReactElement, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';

interface MediaCardPosterProps {
  poster: string;
}

const posterHeight = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'block',
      width: '100%',
      minHeight: posterHeight,
    },
    cardBackground: (props: MediaCardPosterProps) => ({
      background: `url('${props.poster}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
    }),
  }),
);

const MediaCardPoster = ({ poster }: MediaCardPosterProps): ReactElement => {
  const classes = useStyles({ poster });
  const [isPosterLoaded, setIsPosterLoaded] = useState(false);

  const handlePosterLoadedEvent = () => {
    setIsPosterLoaded(true);
  };

  return (
    <>
      <img src={poster} onLoad={() => handlePosterLoadedEvent()} hidden alt='Media' />
      {isPosterLoaded ? (
        <div
          className={clsx(classes.card, {
            [classes.cardBackground]: isPosterLoaded,
          })}
        />
      ) : (
        <div className={classes.card}>
          <Skeleton variant='rect' animation='wave' width='100%' height={posterHeight} />
        </div>
      )}
    </>
  );
};

export default MediaCardPoster;
