import React, { ReactElement, useEffect, useState } from 'react';

import { Avatar } from '@material-ui/core';

import stores from '../../../store';

function CustomAvatar(): ReactElement {
  const { user } = stores.useUserStore();
  const [photoURL, setPhotoURL] = useState('');

  useEffect(() => {
    if (user?.photo) {
      setPhotoURL(user.photo);
    }
  }, [user]);

  return (
    <div>
      <Avatar alt='Avatar photo' src={photoURL} />
    </div>
  );
}

export default CustomAvatar;
