import { Photo } from '../../pages/photos/classes/Photo';
import sharedService from './shared.service';

export class PhotoService {
  async getPhotos(): Promise<Photo[]> {
    const photos = await sharedService.firebaseService.getPhotos();

    const reducedPhotos = photos.reduce(
      (acc: [Photo[], Photo[]], photo: Photo) => {
        acc[photo.isPinned ? 0 : 1].push(photo);
        return acc;
      },
      [[], []],
    );

    return [...reducedPhotos[0], ...reducedPhotos[1]];
  }
}
