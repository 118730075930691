import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { Photo } from '../pages/photos/classes/Photo';
import { Video } from '../pages/videos/classes/Video';
import { User } from '../shared/auth/classes/User';
import serviceProvider from '../shared/services/service.provider';

export type AppState = {
  pageTitle: string;
  setPageTitle: (text: string) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  isSnackbarShown: boolean;
  snackbarText: string;
  openSnackbar: (text: string) => void;
  closeSnackbar: () => void;
};

export type UserState = {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
};

export type MediaState = {
  videos: Video[];
  setVideos: () => void;
  isVideosLoaded: boolean;
  photos: Photo[];
  setPhotos: () => void;
  isPhotosLoaded: boolean;
};

const useAppStore = create<AppState>(
  devtools(set => ({
    pageTitle: '',
    setPageTitle: (pageTitle: string) => set({ pageTitle }),
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
    isSnackbarShown: false,
    snackbarText: '',
    openSnackbar: (text: string) => set({ snackbarText: text, isSnackbarShown: true }),
    closeSnackbar: () => set({ snackbarText: '', isSnackbarShown: false }),
  })),
);

const useUserStore = create<UserState>(
  devtools(set => ({
    user: undefined,
    setUser: (user: User | undefined) => {
      useAppStore.setState({ isLoggedIn: !!user });
      set({ user });
    },
  })),
);

const useMediaStore = create<MediaState>(
  devtools((set, get) => ({
    videos: [],
    setVideos: async () => {
      const videosLoaded = get().videos.length > 0;
      const videos = videosLoaded ? get().videos : await serviceProvider.videoService.getVideos();
      set({ videos, isVideosLoaded: videos?.length > 0 });
    },
    isVideosLoaded: false,
    photos: [],
    setPhotos: async () => {
      const photosLoaded = get().photos.length > 0;
      const photos = photosLoaded ? get().photos : await serviceProvider.photoService.getPhotos();
      set({ photos, isPhotosLoaded: photos?.length > 0 });
    },
    isPhotosLoaded: false,
  })),
);

export default {
  useAppStore,
  useUserStore,
  useMediaStore,
};
