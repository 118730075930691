import React, { ReactElement } from 'react';
import { Box, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Photo } from '../../../pages/photos/classes/Photo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    galleryImage: {
      maxWidth: '100%',
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

const PhotoMedia = ({ title, url, galleryUrl }: Partial<Photo>): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Box pb={3}>
        <Typography variant='body1'>A galéria a képre kattintva érhető el.</Typography>
      </Box>
      <Grid container direction='row' justify='center' alignItems='center'>
        {galleryUrl ? (
          <a href={galleryUrl} target='_blank' rel='noreferrer nofollow'>
            <img className={classes.galleryImage} src={url} alt={title} />
          </a>
        ) : null}
      </Grid>
    </>
  );
};

export default PhotoMedia;
