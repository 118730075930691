import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

interface TagProp {
  text: string;
}

const useStyles = makeStyles(theme => ({
  tag: {
    display: 'inline-block',
    backgroundColor: '#F5F5F5',
    color: '#707070',
    padding: theme.spacing(1),
    borderRadius: 3,
    fontSize: 12,
  },
}));

const Tag = ({ text }: TagProp): ReactElement => {
  const classes = useStyles();

  return <span className={classes.tag}>{text}</span>;
};

export default Tag;
