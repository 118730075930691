import { FirebaseService } from './firebase.service';

class SharedService {
  private firebaseServiceInstance!: FirebaseService;

  get firebaseService(): FirebaseService {
    if (!this.firebaseServiceInstance) {
      this.firebaseServiceInstance = new FirebaseService();
    }

    return this.firebaseServiceInstance;
  }
}

export default new SharedService();
