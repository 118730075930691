import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Box, Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import CalendarIcon from '@material-ui/icons/Today';

import clsx from 'clsx';

import { deployDate, sha } from '../../__deploy__/app.info.json';
import { RouteProp } from '../../classes/RouteProp';
import stores from '../../store';
import { User } from '../auth/classes/User';
import Avatar from '../components/avatar/Avatar';
import formatDate from '../utils/format-date.util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      paddingLeft: theme.spacing(2),
    },
    avatarBox: {
      display: 'flex',
      alignItems: 'center',

      '& .name': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

const DrawerInside = ({
  isMobile,
  routes,
  handleDrawerToggle,
}: {
  isMobile?: boolean;
  handleDrawerToggle: () => void;
  routes: RouteProp[];
}): ReactElement => {
  const classes = useStyles();
  const user = stores.useUserStore<User | undefined>(state => state.user);

  return (
    <div>
      <div className={classes.toolbar}>
        {user ? (
          <Box className={classes.avatarBox}>
            <Avatar />
            <span className={clsx(classes.avatarBox, 'name')}>Szia {user.displayName}!</span>
          </Box>
        ) : null}
      </div>
      <Divider />
      <List>
        {routes.map(({ text, link, icon }) => (
          <ListItem button key={text} component={Link} to={link} onClick={() => isMobile && handleDrawerToggle()}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {user && user.lastLogin ? (
          <ListItem>
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary={`Utolsó belépés: ${formatDate(user.lastLogin, true)}`} />
          </ListItem>
        ) : null}
        {deployDate ? (
          <ListItem>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <Tooltip title={<div>{`App dátum: ${formatDate(deployDate, true)} / ${sha}`}</div>} placement='bottom-start'>
              <ListItemText primary='Infó' />
            </Tooltip>
          </ListItem>
        ) : null}
      </List>
    </div>
  );
};

export default DrawerInside;
