import React, { ReactElement } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: theme.palette.background.paper,
    },
    loader: {
      color: theme.palette.text.primary,

      '&.loaderText': {
        paddingTop: theme.spacing(3),
      },
    },
  }),
);

const AppLoader = (): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress className={classes.loader} size='6rem' thickness={5} />
      <Typography className={clsx(classes.loader, 'loaderText')} variant={'h6'} color={'secondary'}>
        Betöltés...
      </Typography>
    </Box>
  );
};

export default AppLoader;
