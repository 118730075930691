import React, { ReactElement, useEffect } from 'react';

import { Route } from 'react-router-dom';

import { MediaItem } from '../../classes/MediaItem';
import withGuard from '../../shared/auth/hoc/withGuard';
import MediaDialog from '../../shared/components/media-dialog/MediaDialog';
import useMediaSelection from '../../shared/hooks/useMediaSelection';
import MediaGrid from '../../shared/layout/MediaGrid';
import { photoToMediaItem } from '../../shared/utils/photo.adapter';
import stores from '../../store';
import { Photo } from './classes/Photo';

const Photos = (): ReactElement => {
  const route = 'photos';
  const setPhotos = stores.useMediaStore(state => state.setPhotos);
  const setPageTitle = stores.useAppStore(state => state.setPageTitle);
  const photos: MediaItem[] = stores.useMediaStore(state => state.photos).map((photo: Photo) => photoToMediaItem(photo));

  const isPhotosLoaded = stores.useMediaStore(state => state.isPhotosLoaded);
  const storedPhotos = stores.useMediaStore(state => state.photos);

  const [handleMediaSelection, selectedMedia] = useMediaSelection({ isMediaLoaded: isPhotosLoaded, medias: storedPhotos });

  useEffect(() => {
    setPhotos();
  }, [setPhotos]);

  useEffect(() => {
    setPageTitle(`Fotók (${storedPhotos.length} darab galéria)`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos.length]);

  return (
    <>
      <MediaGrid isListLoading={!isPhotosLoaded} items={photos} modalRoute={route} onMediaSelected={handleMediaSelection} />
      <Route
        path='/photos/:short'
        component={() => (
          <MediaDialog media={selectedMedia} medias={storedPhotos} isMediaLoaded={isPhotosLoaded} navigateBackRoute='/photos' />
        )}
      />
    </>
  );
};

export default withGuard(Photos);
