import React, { ReactElement, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Box, createStyles, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { GoogleLoginButton } from 'react-social-login-buttons';

import useLogout from '../../shared/hooks/useLogout';
import { FirebaseService } from '../../shared/services/firebase.service';
import stores from '../../store';

const useStyles = makeStyles(() =>
  createStyles({
    loginContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .google-login': {
        maxWidth: 350,
      },
    },
  }),
);

const Login = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const setUser = stores.useUserStore(storeState => storeState.setUser);
  const { openSnackbar } = stores.useAppStore();

  const checkLoginState = async (): Promise<void> => {
    await FirebaseService.logout();
    setUser(undefined);
  };

  useEffect(() => {
    checkLoginState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    try {
      const credentials = await FirebaseService.signInWithGooglePopup();
      const { uid: userId, displayName, email, photoURL } = credentials.user;
      const user = await FirebaseService.getUserById(userId);

      if (!user) {
        await FirebaseService.createUser(userId, displayName || email || 'Anonymous', email || '', photoURL || '');
        openSnackbar('Felhasználói fiók létrehozva');
        history.push({
          pathname: '/first-login',
          search: new URLSearchParams({ email: email || '' }).toString(),
        });
        return;
      }

      // has user
      // but not email is not verified
      if (!user.isConfirmed) {
        openSnackbar('Nem aktivált felhasználó!');
        history.push({
          pathname: '/first-login',
          search: new URLSearchParams({ email: email || '' }).toString(),
        });
        return;
      }

      // verified, set login date
      await FirebaseService.setUserLastLoginDate(userId);
      openSnackbar('Sikeres bejelentkezés! Üdv újra itt!');
      setUser(user);
      history.push('/videos');
    } catch (err) {
      console.error(err);
      openSnackbar('Hiba történt a bejelentkezés során!');
    }
  };

  return (
    <Box className={classes.loginContainer}>
      <GoogleLoginButton className={clsx(classes.loginContainer, 'google-login')} onClick={() => handleLogin()}>
        <span>Bejelentkezés Google fiókkal</span>
      </GoogleLoginButton>
    </Box>
  );
};

export default Login;
