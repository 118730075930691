import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import { Photo } from '../../../pages/photos/classes/Photo';
import { Video } from '../../../pages/videos/classes/Video';
import MediaSkeleton from '../skeleton/MediaSkeleton';
import PhotoMedia from './PhotoMedia';
import VideoMedia from './VideoMedia';

const useStyles = makeStyles(() =>
  createStyles({
    scrollPaper: {
      alignItems: 'baseline',
    },
    date: {
      fontSize: 12,
    },
    dialogTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 5,
    },
  }),
);

type Media = Video | Photo;

interface MediaDialogParams {
  short: string;
}

interface MediaDialogProps {
  media?: Media;
  medias: (Photo | Video)[];
  isMediaLoaded: boolean;
  navigateBackRoute: string;
  photoUrl?: string;
}

const MediaDialog = ({ media, medias, isMediaLoaded, navigateBackRoute }: MediaDialogProps): ReactElement => {
  const history = useHistory();
  const { short: shortName } = useParams<MediaDialogParams>();
  const classes = useStyles();
  const [selectedMedia, setSelectedMedia] = useState<Media>();
  const [isImageGallery, setIsImageGallery] = useState(false);

  useEffect(() => {
    if (isMediaLoaded) {
      handleSelectedMedia(media || medias.find(({ short }) => short === shortName));
    }
  }, [isMediaLoaded, media, medias, shortName]);

  const handleClose = () => {
    history.push(navigateBackRoute);
  };

  const handleSelectedMedia = (updatedMedia: Media | undefined) => {
    setSelectedMedia(updatedMedia);
    if (updatedMedia) {
      setIsImageGallery(!('ytID' in updatedMedia));
    }
  };

  const getMediaComponent = (): ReactElement => {
    if (isImageGallery) {
      const { title, url, galleryUrl }: Photo = selectedMedia as Photo;
      return <PhotoMedia title={title} url={url} galleryUrl={galleryUrl} />;
    }

    const { ytID, comment, download, downloadHQ }: Video = selectedMedia as Video;
    return <VideoMedia ytID={ytID} comment={comment} download={download} downloadHQ={downloadHQ} />;
  };

  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      open
      onClose={handleClose}
      classes={{ scrollPaper: classes.scrollPaper }}
      aria-labelledby='max-width-dialog-title'
    >
      {isMediaLoaded && selectedMedia ? (
        <>
          <DialogTitle id='max-width-dialog-title' className={classes.dialogTitle} disableTypography>
            <span style={{ textAlign: 'left', width: '100%' }}>
              <Typography variant={'h5'} display={'inline'}>
                {selectedMedia.title}
              </Typography>{' '}
              {'date' in selectedMedia ? <span className={classes.date}>({selectedMedia.date})</span> : null}
            </span>
            <span style={{ textAlign: 'right' }}>
              <Tooltip title='Vissza' placement={'bottom'}>
                <IconButton aria-label='delete' onClick={handleClose}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </span>
            <Box pt={3}>
              <Divider />
            </Box>
          </DialogTitle>

          {/* CONTENT */}
          <DialogContent>{getMediaComponent()}</DialogContent>
        </>
      ) : (
        <DialogContent>
          <MediaSkeleton count={5} variant='rect' width='100%' height={80} />
        </DialogContent>
      )}

      {/* CLOSE */}
      <DialogActions>
        <Button onClick={handleClose} variant='contained'>
          Bezárás
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaDialog;
