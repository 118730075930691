import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { MediaItem } from '../../classes/MediaItem';
import MediaCard from '../components/media-card/MediaCard';

interface MediaGridProps {
  isListLoading: boolean;
  items: MediaItem[];
  modalRoute: 'videos' | 'photos';
  onMediaSelected: (short: string) => void;
}

const defaultGridItemCount = Array.from({ length: 10 }, (_, i) => `skeleton_${i}`);

const MediaGrid = ({ isListLoading, items, modalRoute, onMediaSelected }: MediaGridProps): ReactElement => (
  <Grid container spacing={3}>
    {isListLoading
      ? defaultGridItemCount.map(skeleton => (
          <Grid item lg={4} md={6} xs={12} key={skeleton}>
            <Skeleton variant='rect' height={250} style={{ borderRadius: '10px' }} />
          </Grid>
        ))
      : null}
    {!isListLoading && (!items || items.length === 0) ? (
      <Box textAlign='center' width='100%'>
        <h3>Nem található megjeleníthető elem!</h3>
      </Box>
    ) : null}

    {!isListLoading &&
      items.map(({ short, poster, title, createdDate, isPinned }) => (
        <Grid item lg={4} md={6} xs={12} key={short} onClick={() => onMediaSelected(short)}>
          <Link to={`${modalRoute}/${short}`} style={{ textDecoration: 'none' }}>
            <MediaCard poster={poster} title={title} date={createdDate} isPinned={isPinned} />
          </Link>
        </Grid>
      ))}
  </Grid>
);

export default MediaGrid;
