import { useEffect } from 'react';

import stores from '../../store';
import { FirebaseService } from '../services/firebase.service';

const useLogout = (): void => {
  const setUser = stores.useUserStore(state => state.setUser);

  const handleLogout = async () => {
    await FirebaseService.logout();
    setUser(undefined);
  };

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line
  }, []);
};

export default useLogout;
